import React from "react";
import { Box } from "@chakra-ui/react";
import { StaticImage } from "gatsby-plugin-image";

export function SFVHero() {
  return (
    <Box
      width={{ base: "100%" }}
      maxWidth="100%"
      maxHeight="55rem"
      paddingTop="1rem"
      margin="0 auto"
    >
      <StaticImage
        layout="fullWidth"
        src="../../../../images/header-image-SFV.jpg"
        alt=""
        placeholder="blurred"
        style={{ width: "100%", height: "auto", maxHeight: "inherit" }}
      />
    </Box>
  );
}
