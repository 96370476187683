import React from "react";
import { Layout } from "../components/layout/Layout";
import { MetaTags } from "../components/MetaTags";
import { SFVArticle } from "../components/sections/insight/SFV/SFVArticle";
import { SFVHero } from "../components/sections/insight/SFV/SFVHero";

function EventMarketPage() {
  return (
    <Layout colorScheme="secondary" heroSection={<SFVHero />} isHeroShortBaseOnImage isWhite>
      <SFVArticle />
    </Layout>
  )
}

export default EventMarketPage;

export const Head = () => (
  <MetaTags title="Event marketing - Svenska Folkhögskolans Vänner" />
)