import React from "react";
import { Section } from "../../../layout/Section";
import ArticleHeading from "../../../ArticleHeading";
import { StaticImage } from "gatsby-plugin-image";
import ImageText from "../../../ImageText";
import HeadPara from "../../../HeadPara";
import PicturePara from "../../../PicturePara";
import ArticleTag from "../../../ArticleTag";
import ArticleQuote from "../../../ArticleQuote";
import QuoteAuthor from "../../../QuoteAuthor";
import ArticleContainer from "../../../AlignmentContainer";
import ArticleImageContainer from "../../../ArticleImageContainer";
import { BodyText } from "../../../texts/BodyText";
import ArticleTags from "../../../ArticleTags";
import { ContactMaricaCard } from "../../../cards/ContactMaricaCard";
import { Flex } from "@chakra-ui/react";

export const SFVArticle: React.FC = () => {
  return (
    <Section>
      <Flex direction="column" gap="6.25rem">
        <ArticleContainer>
          <ArticleHeading subtitle="Event marketing – Svenska Folkskolans Vänner " />
          <ArticleTags>
            <ArticleTag tag="Customer" value="Svenska Folkskolans Vänner" />
            <ArticleTag tag="Project" value="Event marketing" />
            <ArticleTag
              tag="What we worked on"
              value="Illustrations, Marketing material"
            />
          </ArticleTags>
          <BodyText>
            In the fall of 2023, we had the privilege of working with graphic
            material for Svenska Folkskolans Vänner. The project involved
            creating illustrations and a flyer to market the event, Mera - Flera
            - Starkare, that focused on future-proofing voluntary engagement.
          </BodyText>
        </ArticleContainer>

        <ArticleImageContainer>
          <StaticImage
            alt=""
            src="../../../../images/1-SFV.jpg"
            placeholder="none"
          />
          <ArticleContainer>
            <ImageText text="The event, Mera - Flera - Starkare, aimed to make voluntary involvement strong for the future." />
          </ArticleContainer>
        </ArticleImageContainer>

        <ArticleContainer>
          <HeadPara medium para="The event" />
          <PicturePara
            para="SFV, is an association dedicated to promoting Swedish-language education in Finland since 1882.
In their full-day event, Mera - Flera - Starkare, they where focused on voluntary engagement. The event aimed to make voluntary involvement strong for the future. Experts gave talks on important topics, like building a lively community for members, ensuring elected officials and volunteers make a real impact, and using passion and commitment wisely. 
The event, held in November, was for association members, employees and anyone interested. "
          />
        </ArticleContainer>

        <ArticleImageContainer>
          <StaticImage
            alt=""
            src="../../../../images/02-SFV.jpg"
            placeholder="none"
          />
          <ArticleContainer>
            <ImageText text="Indevit made a line-art illustration that could function as both a flowchart and individual images." />
          </ArticleContainer>
        </ArticleImageContainer>

        <ArticleContainer>
          <HeadPara medium para="Our collaboration" />
          <PicturePara
            para="Our collaboration began with SFV's request for an illustration that could be divided into 2-3 graphic elements representing the essence of the event: research, inspiration, and workshop. These illustrations were intended for use in invitations, newsletters, social media posts, and event information on their website. 
        The illustration also needed to suit both printed and digital use."
          />
        </ArticleContainer>

        <ArticleImageContainer>
          <StaticImage
            alt=""
            src="../../../../images/03-SFV.jpg"
            placeholder="none"
          />
          <ArticleContainer>
            <ImageText
              text="The illustrations were created for invitations, newsletters, 
        social media posts, and event information on SFV’s website. "
            />
          </ArticleContainer>
        </ArticleImageContainer>

        <ArticleContainer>
          <PicturePara
            para="We initiated the process with a meeting to understand SFV's vision, preferences, and graphic guidelines. 
        We proposed a line-art style for the illustration that could function as both a flowchart and individual images. After receiving feedback on the initial draft, we refined both the illustrations and the flyer layout. The entire package was delivered to them well in advance of their event."
          />
          <PicturePara
            para="SFV's contact person, Christine Skogman, summarised our collaboration by expressing appreciation for the smooth process.
       By understanding SFV's needs early on, we created a visually appealing whole that lived up to their expectations."
          />
          <ArticleQuote
            body=" “Jag uppskattar det smidiga samarbetet. Jag märkte att Julia i ett tidigt skede förstod vad vi var ute efter,
                        och skapade sedan en snygg helhet som motsvarade våra förväntningar.”"
          />
          <QuoteAuthor body=" – Christine Skogman, kommunikationsansvarig" />
        </ArticleContainer>

        <ArticleContainer alignSelf="flex-start">
          <HeadPara medium para="Do you want to know more? Contact us!" />
          <ContactMaricaCard />
        </ArticleContainer>
      </Flex>
    </Section>
  );
};
